import styles from "./footer.module.css";
import { CiLocationOn } from "react-icons/ci";
import { MdOutlineEmail } from "react-icons/md";
import { LuPhone } from "react-icons/lu";
import { IoLogoFacebook } from "react-icons/io";
import { PiLinkedinLogo } from "react-icons/pi";
import { FaInstagram } from "react-icons/fa6";
import { MdOutlineCopyright } from "react-icons/md";
import { motion } from "framer-motion";

function Footer() {
  const year = new Date().getFullYear();
  return (
    <motion.footer
      initial={{ scale: 0, opacity: 0 }}
      animate={{ scale: 1, opacity: 1 }}
      transition={{ duration: 0.5 }}>
      <div className={styles.wrapper}>
        <div className={styles.logo}>
          <h1 className={styles.navh1}>FFRL</h1>
          <div className={styles.socials}>
            <h2 className={styles.footerh2}>Follow us on:</h2>
            <span className={styles.socialswrapper}>
              <IoLogoFacebook className={styles.socialicon} />
              <PiLinkedinLogo className={styles.socialicon} />
              <FaInstagram className={styles.socialicon} />
            </span>
          </div>
        </div>
        <div className={styles.rest}>
          <div className={styles.services}>
            <h2 className={styles.footerh2}>Services</h2>
            <p>Health services</p>
            <p>Cleaning services</p>
            <p>Security services</p>
            <p>Estate monitoring</p>
          </div>
          <div className={styles.quicklinks}>
            <h2 className={styles.footerh2}>Quick Links</h2>
            <p>
              <a href="#home">Home</a>
            </p>
            <p>
              <a href="#about">About</a>
            </p>
            <p>
              <a href="#services">Services</a>
            </p>
            <p>
              <a href="#contact">Contact</a>
            </p>
          </div>
          <div className={styles.contact}>
            <h2 className={styles.footerh2}>Contact Info</h2>
            <div className={styles.containinfo}>
              <div>
                <CiLocationOn /> 65 Woodyard Avenue, Chesterfield, S41 7WB
                United Kingdom
              </div>
              <div>
                <MdOutlineEmail /> info@famousfacilityresourceslimited.co.uk
              </div>
              <div>
                <LuPhone /> +442037848703
              </div>
            </div>
          </div>
        </div>

        <div className={styles.socialsmob}>
          <h2 className={styles.footerh2}>Follow us on:</h2>
          <span className={styles.socialswrappermob}>
            <IoLogoFacebook className={styles.socialicon} />
            <PiLinkedinLogo className={styles.socialicon} />
            <FaInstagram className={styles.socialicon} />
          </span>
        </div>
      </div>

      <div className={styles.lines}>
        <div>
          <MdOutlineCopyright /> <p>{year} Famous Facility Resources Limited</p>
        </div>
      </div>
    </motion.footer>
  );
}

export default Footer;
