import styles from "./testimonials.module.css";
import { motion } from "framer-motion";

function Testimonials() {
  return (
    <motion.section
      className={styles.wrapper}
      initial={{ scale: 0, opacity: 0 }}
      animate={{ scale: 1, opacity: 1 }}
      transition={{ duration: 0.5 }}>
      <div className={styles.testimonial}>
        <h2>Testimonials Of Happy Clients</h2>
        <p>
          We have provided stability and reliability to <br /> every of our
          clients
        </p>
      </div>
      <div className={styles.testimonial2}>
        <div className={styles.usertext}>
          <h3>Adakole Lopesz Martins</h3>
          <p>Floor cleaning</p>
          <p>
            Their commitment to excellence, combined with a friendly and
            professional approach, sets them apart in the industry. Our home has
            never looked or felt this clean, and we are grateful for the
            exceptional service{" "}
          </p>
        </div>
      </div>
    </motion.section>
  );
}

export default Testimonials;
