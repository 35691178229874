import sheild from '../../assets/images/securitysheild.png'
import toilet from '../../assets/images/toiletsink.png'
import estate from '../../assets/images/hometower.png'
import activity from '../../assets/images/activity.png'

export const ourservicesdata = [
  {
    id: 1,
    title: 'Cleaning service',
    content: 'Revitalize your interiors with our specialized cleaning services, leaving your carpets and upholstery looking and feeling brand new.',
    icon: `${toilet}`
  },
  {
    id: 2,
    title: 'Estate monitoring',
    content: 'We deploy state-of-the-art surveillance technology to monitor and secure your property 24/7, ensuring peace of mind.',
    icon: `${estate}`
  },
  {
    id: 3,
    title: 'Security service',
    content: 'Our dedicated security personnel conduct regular patrols, safeguarding your estate and providing a visible deterrent to potential threats.',
    icon: `${sheild}`
  },
  {
    id: 4,
    title: 'Health and safety inspection',
    content: "it's our passion, our purpose, and our promise. We understand that a safe and healthy workplace isn't just essential for compliance, it's the cornerstone of productivity, morale, and success.",
    icon: `${activity}`
  }   
]