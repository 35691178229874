import styles from "./contactus.module.css";
import { FiPhone } from "react-icons/fi";
import { MdOutlineMail } from "react-icons/md";
import { ValidationError, useForm } from "@formspree/react";
import {motion} from "framer-motion"

function Contactus() {
  const formkey = "mbjndqwo";
  const [state, handleSubmit] = useForm(formkey);

  return (
    <motion.section id="contact" className={styles.wrapper} initial={{ scale: 0, opacity: 0 }}
    animate={{ scale: 1, opacity: 1 }}
    transition={{ duration: 0.5 }}>
      <div className={styles.cotain}>
        <div className={styles.contactext}>
          <h1 className={styles.contacth1}>Talk to us about your Project</h1>
          <p>We offer only Professionals and Experience services</p>
          <p>Any problem about booking you can contact us on :</p>
          <div className={styles.phone}>
            <span>
              <FiPhone /> Phone number
            </span>
            <p>+442037848703</p>
          </div>
          <div className={styles.phone}>
            <span>
              <MdOutlineMail /> Email
            </span>
            <p>info@famousfacilityresourceslimited.co.uk</p>
          </div>
        </div>
        <form onSubmit={handleSubmit} className={styles.contactform}>
          <h4>Contact Us</h4>
          <p>Let's schedule a discussion </p>
          <p style={{color: 'orange'}}>{!state.submitting && state.succeeded ? "Submited Succesfully" : ''}</p>
          <p style={{color: 'red'}}>{!state.submitting && state.errors ? "Error: Please Try again" : ''}</p>
          <div>
            <input
              type="text"
              id="fullname"
              name="fullname"
              placeholder="Full Name"
            />
            <ValidationError
              prefix="fullname"
              field="fullname"
              errors={state.errors}
            />
            <input
              type="text"
              id="phone"
              name="phone"
              placeholder="Phone Number"
            />
            <ValidationError
              prefix="phone"
              field="phone"
              errors={state.errors}
            />

            <input
              type="email"
              id="email"
              name="email"
              placeholder="Email adress"
            />
            <ValidationError
              prefix="Email"
              field="email"
              errors={state.errors}
            />

            <textarea
              id="message"
              name="message"
              placeholder="Description"></textarea>

            <ValidationError
              prefix="Message"
              field="message"
              errors={state.errors}
            />
          </div>

          <button
            type="submit"
            disabled={state.submitting}
            className={styles.contacbtn}>
            Let's go
          </button>
        </form>
      </div>
    </motion.section>
  );
}

export default Contactus;
