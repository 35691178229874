import styles from './aboutus.module.css'
import cleaner from '../../assets/images/cleaner.png'
import check from '../../assets/images/checkmark.png'
import deepblue from '../../assets/images/deepblue.png'
import lightblue from '../../assets/images/lightblue.png'
import {motion} from 'framer-motion'


function Aboutus() {
  return (
    <motion.section id='about' className={styles.wrapper} initial={{ scale: 0, opacity: 0 }}
    animate={{ scale: 1, opacity: 1 }}
    transition={{ duration: 0.5 }}>
      <div className={styles.aboutimage}>
        <div className={styles.deepblue}>
          <img src={deepblue}  alt="" />
        </div>
        <div className={styles.lightblue}>
          <img src={lightblue}  alt="" />
        </div>
        <div className={styles.cleaner}>
          <img src={cleaner} alt="CleanerImage" />
        </div>
      </div>
      <div className={styles.aboutext}>
        <h4 className={styles.abouth4}>About Us</h4>
        <h2 className={styles.abouth2}>We are the best facility <br /> providers</h2>
        <p className={styles.aboutp}>We redefine the standard of living by seamlessly combining top-tier cleaning services with cutting-edge estate monitoring and security service.</p>

        <div className={styles.qualities}>
          <span className={styles.eachQuality}><img src={check} alt="checkmark" />Excellency </span>
          <span className={styles.eachQuality}><img src={check} alt="checkmark" />Quality assurance</span>
          <span className={styles.eachQuality}><img src={check} alt="checkmark" />Service delivery</span>
        </div>

        <a href="#services"><button className={styles.explorebtn}>Explore more</button></a>
      </div>
    </motion.section>
  )
}

export default Aboutus
