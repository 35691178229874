import styles from "./herosection.module.css";
import officer from "../../assets/images/officer.png";
import officer2 from "../../assets/images/officerMob.png";
import topright from "../../assets/images/topright.png";
import topleft from "../../assets/images/topleft.png";
import bottomleft from "../../assets/images/bottomleft.png";
import bytheofficer from "../../assets/images/bytheofficer.png";
import curvedblue from "../../assets/images/Ellipse 14.png";
import leftligtblue from "../../assets/images/Ellipse 15.png";
import { motion } from "framer-motion";

function Herosection() {
  return (
    <motion.section
      id="home"
      className={styles.wrapper}
      initial={{ scale: 0, opacity: 0 }}
      animate={{ scale: 1, opacity: 1 }}
      exit={{ scale: 0, opacity: 0 }}
      transition={{ duration: 0.5 }}>
      <div className={styles.herotext}>
        <div>
          <h1 className={styles.heroh1}>We provide the</h1>
          <div className={styles.wordspin}>
            <h1 className={styles.heroh1}>best</h1>
            <div className={styles.spinwords}>
              <span>Cleaning Service</span>
              <span>Estate Monitoring</span>
              <span>Security Service</span>
            </div>
          </div>
          <p style={{ marginTop: "10px" }} className={styles.herop}>
            We are committed to the best quality of service
          </p>
          <a href="#contact">
            <button className={styles.contactbtn}>Contact Us</button>
          </a>
        </div>
      </div>
      <div className={styles.heroimage}>
        <div className={styles.officer}>
          <img src={officer} alt="Security Guard" />
          <img src={officer2} alt="Security Guard" />
        </div>
        <div className={styles.topright}>
          <img src={topright} alt="topright" />
        </div>
        <div className={styles.topleft}>
          <img src={topleft} alt="topleft" />
        </div>
        <div className={styles.bottomleft}>
          <img src={bottomleft} alt="bottomleft" />
        </div>
        <div className={styles.curvedblue}>
          <img src={curvedblue} alt="bottomleft" />
        </div>
        <div className={styles.leftligtblue}>
          <img src={leftligtblue} alt="bottomleft" />
        </div>
        <div className={styles.bytheofficer}>
          <img src={bytheofficer} alt="bottomleft" />
        </div>
      </div>
    </motion.section>
  );
}

export default Herosection;

// {/* <motion.div
// className={styles.spinwords}
// initial={{ y: '-10%' }}
// animate={{ y: "-213%" }}
// transition={{ duration: 15, ease: "easeOut", repeat: Infinity }}>
// <span>Service 1</span>
// <span>Service 2</span>
// <span>Service 3</span>
// </motion.div> */}
