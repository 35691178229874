import Aboutus from './components/aboutus/aboutus';
import Contactus from './components/contactus/contactus';
import Footer from './components/footer/footer';
import Header from './components/header/header';
import Herosection from './components/herosection/herosection';
import Ourservices from './components/ourservices/ourservices';
import Testimonials from './components/testimonials/testimonials';
import './index.css';

function App() {
  return (
    <div className='wrapper'>
      <Header />
      <Herosection />
      <Aboutus />
      <Ourservices />
      <Contactus />
      <Testimonials />
      <Footer />
    </div>
  );
}

export default App;
