import styles from './header.module.css'
import logo from '../../assets/images/3-removebg-preview.png'
import { IoMdMenu } from "react-icons/io";
import { IoIosCloseCircleOutline } from "react-icons/io";
import { useState } from 'react';

function Header() {
  const [dropdown, setDropdown] = useState(false)


  const toggleMenuOpen = () => {
    setDropdown(true)
  }
  const toggleMenuClose = () => {
    setDropdown(false)
  }

  return (
    <nav className={styles.wrapper}>
      <div className={styles.navbar}>
        <div className={styles.logo}>
          <img src={logo} alt="" />
        </div>

        <div onClick={toggleMenuClose} className={dropdown ? styles.navListWrapper : styles.closeListWrapper}>
        </div>
          <ul className={dropdown ? styles.navlist : styles.closelist}>
            <li onClick={toggleMenuClose}><a href='#home'>Home</a></li>
            <li onClick={toggleMenuClose}><a href='#about'>About us</a></li>
            <li onClick={toggleMenuClose}><a href='#services'>Services</a></li>
            <li onClick={toggleMenuClose}><a href='#contact'>Contact us</a></li>
            <IoIosCloseCircleOutline onClick={toggleMenuClose} className={dropdown ? styles.closeIcon : styles.closeIcon2} />
          </ul>


        <IoMdMenu onClick={toggleMenuOpen}  className={styles.hamburger} />
      </div>
    </nav>
  )
}

export default Header
