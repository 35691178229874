import styles from "./ourservices.module.css";
import { ourservicesdata } from "./ourservicesdata";
import { BsTruck } from "react-icons/bs";
import { motion } from "framer-motion";

function Ourservices() {
  return (
    <motion.section
      id="services"
      className={styles.wrapper}
      initial={{ scale: 0, opacity: 0 }}
      animate={{ scale: 1, opacity: 1 }}
      transition={{ duration: 0.5 }}>
      <h1 className={styles.serviceh1}>
        Our <span className={styles.only}>Services</span>
      </h1>
      <p className={styles.servicep}>
        We take pride in creating spaces that are not only impeccably clean but
        also <br />
        secure and well-maintained.
      </p>
      <h1 className={styles.only}>&</h1>
      <h3 className={styles.serviceh3}>
        We can get started with 20% mobilization fee{" "}
        <BsTruck className={styles.mobilize} />
      </h3>
      <div className={styles.services}>
        <div className={styles.servicetrack}>
          {ourservicesdata.map((item) => (
            <div key={item.id}>
              <div className={styles.serviceitem}>
                <span className={styles.circle}>
                  <div className={styles.serviceicon}>
                    <img src={item.icon} alt="" />
                  </div>
                </span>
                <h3>{item.title}</h3>
                <p>{item.content}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </motion.section>
  );
}

export default Ourservices;
